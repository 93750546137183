import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/mobile/index.scss';
import './i18n';
import {Api} from './js/Api';
import {VisualleafletModal} from './components/LeafletModal';
import {RelatedVideo} from './components/RelatedVideo';
import Main from './Main';
import * as serviceWorker from './serviceWorker';

import './fonts/calibri/Calibri-Bold.TTF';
import './fonts/calibri/Calibri-Bold-Italic.ttf';
import './fonts/calibri/Calibri-Italic.ttf';
import './fonts/calibri/Calibri-Light.ttf';
import './fonts/calibri/Calibri-Light-Italic.ttf';
import './fonts/calibri/Calibri-Regular.ttf';

ReactDOM.render(<Router>
    <Routes>
        <Route exact path="/" element={<Main />}>
            <Route exact path="/#beeldsluiters" element={<Api />} />
            <Route exact path="/:name" element={<VisualleafletModal />} />
            <Route exact path="/leaflet/:name" element={<VisualleafletModal />} />
            <Route exact path="/video/:title" element={<RelatedVideo />} />
        </Route>
    </Routes>
</Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
