import React, { Component } from "react";
import ReactDOM from "react-dom";
import Modal from 'react-bootstrap/Modal';
import { Trans } from "react-i18next";
import * as Models from "../models";
import Carousel from 'react-multi-carousel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    IconName,
    IconPrefix,
    library
} from '@fortawesome/fontawesome-svg-core'

import IntakeMoment from './Intakemoments';
const tagManagerArgs = {
    gtmId: 'GTM-5HGRXN9',
    events: {
        visualleaflet_modal: 'visualleaflet_modal'
    }
}

type ModalState = {
    video?: Models.Video,
    leaflet?: Models.Visualleaflet,
    language?: Models.Language,
    show: boolean,
    activeVideo: any,
    widget: any,
    itemref: any,
    condition: boolean,
    relatedVideos: Models.RelatedVideo[] | "loading" | "error",
    modalTitle: string,
    isRelatedVideo: boolean,
    intakeMoments: Models.IntakeMoment[],
    reportSideEffect: any,
    sideEffectRef: any,

}

type ModalProps = {
    activeVideo: Models.Video,
    isQRReference?: boolean,
    leaflet?: Models.Visualleaflet,
    language?: Models.Language,
    video?: Models.Video,
}


export class VisualleafletModal extends Component<ModalProps, ModalState> {
    constructor(params: any, state: any) {
        super(params, state);
        this.state = {
            video: this.props.video,
            activeVideo: this.props.activeVideo != null ? this.props.activeVideo :
                this.props.leaflet != null ? (this.props.leaflet.videos.length > 0 ? this.props.leaflet.videos.filter((vid: Models.Video) => vid.language.code == this.props.language.code)[0] : { id: 1 })
                    : { id: 1 },
            show: this.props.isQRReference != null ? true : false,
            widget: "loading",
            itemref: React.createRef(),
            condition: false,
            relatedVideos: "loading",
            modalTitle: (this.props.leaflet != null) ? this.props.leaflet.name : this.props.video.title,
            language: this.props.language,
            isRelatedVideo: !(this.props.leaflet != null),
            intakeMoments: [],
            reportSideEffect: "loading",
            sideEffectRef: React.createRef(),

        }

        library.add(fas, fab);
    }


    componentWillReceiveProps(newProps: ModalProps) {
        let acv = newProps.activeVideo;
        this.setState({
            activeVideo: acv != null ? acv : this.state.leaflet.videos[0],
            leaflet: newProps.leaflet,
            language: newProps.language,
        })
    }

    showModal = (v: Models.Visualleaflet) => {
        this.setState({ show: true, leaflet: v });
    };

    hideModal = () => {
        this.setState({
            activeVideo: this.props.activeVideo,
            modalTitle: (this.props.leaflet != null) ? this.props.leaflet.name : this.props.video.title,
            show: false
        })
    };

    componentDidMount() {
        window.addEventListener('popstate', this.handlePopstate);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.handlePopstate);
    }

    handlePopstate = () => {
        this.hideModal();
    };

    async getIntakeMoments(videoId: number) {
        let allIntakeMoments = await fetch("/api/intakemoments/");
        allIntakeMoments = await allIntakeMoments.json();

        let intakeMoments = (allIntakeMoments as unknown as Models.IntakeMoment[]).filter((x: Models.IntakeMoment) => x.beeldsluiter == videoId)
        return intakeMoments
    }

    toggleNavigation() {
        this.setState({ ...this.state, condition: !this.state.condition });
    }

    createWidget() {
        this.changeVideo(this.state.activeVideo.id);
    }

    async changeVideo(id: number) {
        try {
            this.setState({
                widget: new window.Widget(this.state.itemref.current, {
                    video: id,
                    autoplay: false,
                    display_report_side_efffect_button: false,
                    controls: true,
                    display_related_urls: false,
                    display_description: false,
                    display_related_videos: false,
                    display_cooperation: false,
                    new_rvg_design: true,
                    distributor: "ISO639CODE-COMPANY-ZIP",
                    title_placement: 'hidden',
                    WDNRRVG: this.props.isQRReference != null ? this.props.isQRReference : false,
                    elearn_button: false,
                }),
            })

            this.getVideo(id)
                .then((vid: Models.Video) => {
                    this.setState({ activeVideo: vid });
                })
                .then(() => {
                    const selector = '.report-side-effect';
                    const reportSideEffectElement = document.querySelector(selector);

                    if (reportSideEffectElement !== null) {
                        this.setState({
                            reportSideEffect: new window.Bijwerkingentool(selector),
                        });
                    }
                });

            this.getRelatedVideos(id).then((videos: Models.RelatedVideo[]) => {
                this.setState({ relatedVideos: videos });
            });

            this.getIntakeMoments(this.state.activeVideo.id).then((intakeMoments: Models.IntakeMoment[]) => {
                this.setState({ intakeMoments: intakeMoments });
            });

        } catch (ex) {
            console.log(ex);
        }
    }

    async getRelatedVideos(id: number): Promise<Models.RelatedVideo[]> {
        const res = await fetch(`/api/related-video-buttons/${id}/`);
        return res.json();
    }

    async getVideo(id: number): Promise<Models.Video> {
        const res = await fetch(`/api/videos/${id}/`);
        return res.json();
    }

    render() {
        if (this.state.widget != "loading")
            this.state.widget.render()
        const item = this.state.activeVideo;
        const leaflet = this.state.leaflet;
        const related = this.state.isRelatedVideo;

        let linkToLeaflet = window.location.hostname + '?ref=qr&vid_id=' + item.id;

        return (
            <Modal
                size="xl"
                dialogClassName="modal-custom"
                show={this.state.show}
                onHide={() => { this.hideModal(); window.history.go(-1)}}
                onShow={() => { this.createWidget() }}
                aria-labelledby="modal-title"
                data-test="visual-leaflet-modal"
            >

                <Modal.Header closeButton>
                    <Modal.Title id="modal-title">
                        <h2>{this.state.modalTitle}</h2>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body id="example-modal-sizes-body-xl" className="row">
                    <div className={item.link_buttons != undefined ? (item.link_buttons.length > 0 ? 'col-sm-12 col-lg-8 order-lg-1 order-md-1 order-1' : 'col-sm-12 col-lg-12 order-lg-1 order-md-1 order-1') : 'col-sm-12 col-lg-12 order-lg-1 order-md-1 order-1'}>
                        <div className="row">
                            <div id={"leaflet-video-id-" + item.id} className="col-sm-12 leaflet-video-container" ref={this.state.itemref}></div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 order-lg-2 order-md-2 order-1 video-extra-info-container" style={item.link_buttons != undefined ? (item.link_buttons.length > 0 ? {} : { display: "none" }) : { display: "none" }}>
                        {related ? '' :
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="indication-substance-list">
                                        <p><li className="km_bold"><Trans>Indicatie</Trans></li>{item.indication != undefined ? <li>{item.indication.name}</li> : ''}</p>
                                        <p><li className="km_bold"><Trans>Werkzame stof</Trans></li>{item.substance != undefined ? <li>{item.substance.name}</li> : ''}</p>
                                    </ul>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-sm-12">
                                {item.description != undefined ? <p className="video-leaflet-description">{item.description}</p> :
                                    leaflet != null ? <p className="video-leaflet-description">{leaflet.video_description}</p> : ''}
                            </div>
                        </div>

                        {(item.cooperation != undefined && item.cooperation.trim()) != '' ?
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="cooperation">{item.cooperation}</div>
                                </div>
                            </div>
                            : ''}

                        <div className="row">
                            {item.link_buttons != undefined || item.display_side_effect_button ?
                                <div className="col-sm-12">
                                    <ul className="video-leaflet-link-buttons row">

                                        {/* <!-- link buttons --> */}
                                        {item.link_buttons != undefined ? item.link_buttons.map((link_button: Models.LinkButton) => (
                                            <li className="col-sm-12">
                                                <a onClick={() => { window.bs_dataLayer.push({ 'event': 'visualleaflet_modal_link_clicked', 'visualleaflet_modal_link_name': link_button.text }); }} href={link_button.url} target={link_button.target} className="link" data-test="btn-video-link">
                                                    <FontAwesomeIcon icon={[link_button.icon.split(' ')[0] as IconPrefix, link_button.icon.split(' ')[1] as IconName]} /> <span>{link_button.text}</span>
                                                </a>
                                            </li>
                                        )) : ''}
                                        {item.elearner ?
                                            <li className="col-sm-12">
                                                <a
                                                    className="link video-learner-button"
                                                    onClick={() => {
                                                        window.bs_dataLayer.push(
                                                            {
                                                                'event': 'e_learning_clicked',
                                                            })
                                                    }}>
                                                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span><Trans>E-learning</Trans></span>
                                                </a>
                                                <div className="video-learning-container"></div>
                                            </li>
                                            : ''}
                                        {/* This button is meant to set an intake moment in the google calendar */}
                                        {this.state.intakeMoments.length > 0 ?
                                            <li className="col-sm-12">
                                                <IntakeMoment EventTitle={this.state.leaflet.name} Events={this.state.intakeMoments} />
                                            </li> : ''
                                        }
                                        {
                                            (item.display_side_effect_button || item.use_new_report_button) && (
                                                <li className="col-sm-12">
                                                    {
                                                        item.use_new_report_button ? (
                                                            <button
                                                                className="link report-side-effect-button js--meld-bijwerking-button"
                                                                data-test="btn-report-side-effect"
                                                            >
                                                                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span><Trans>Meld bijwerking</Trans></span>
                                                            </button>
                                                        ) : (
                                                            <a
                                                                href="https://www.lareb.nl/meldformulier"
                                                                target="_blank"
                                                                className="link report-side-effect-button js--meld-bijwerking-button"
                                                                data-test="btn-report-side-effect"
                                                                ref={this.state.sideEffectRef}
                                                            >
                                                                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span><Trans>Meld bijwerking</Trans></span>
                                                            </a>
                                                        )
                                                    }
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                : ''}
                        </div>

                        {item.use_new_report_button ?
                            <div className="report-side-effect"></div>
                            : <></>}

                        <div className="row">
                            <div className="col-sm-12">
                                <p className="km_bold">
                                    <Trans>Deel deze beeldsluiter</Trans>
                                </p>
                                {
                                    <a href={"mailto:?subject=Beeldsluiter&body=Bekijk%20deze%20Beeldsluiter:%0D%0A%0D%0A" + encodeURIComponent(linkToLeaflet)} className="video-leaflet-share-button" data-test="btn-share-email">
                                        <FontAwesomeIcon icon={['fas', 'envelope']} />
                                    </a>
                                }
                                {
                                    <a href={"whatsapp://send?text=" + "Bekijk%20deze%20Beeldsluiter:%20" + encodeURIComponent(linkToLeaflet)} className="video-leaflet-share-button" data-test="btn-share-whatsapp">
                                        <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                                    </a>
                                }
                                {
                                    item.title != "Xospata" ?
                                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(linkToLeaflet)} className="video-leaflet-share-button" data-test="btn-share-facebook">
                                            <FontAwesomeIcon icon={['fab', 'facebook']} />
                                        </a> : <a></a>
                                }
                                {
                                    item.title != "Xospata" ?
                                        <a href={"https://twitter.com/intent/tweet?text=" + encodeURIComponent(linkToLeaflet)} className="video-leaflet-share-button" data-test="btn-share-x">
                                            <FontAwesomeIcon icon={['fab', 'x-twitter']} />
                                        </a> : <a></a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-8 order-lg-3 order-md-3 order-2 related-videos-link-container">
                        <div className="row">
                            {item.image_link_buttons != undefined ?
                                <div className="col-sm-12">
                                    <h3 className="related-videos-link-title">Extra</h3>
                                    <span className="line medium-margin-bottom" ></span>
                                    {/* <!-- image link buttons -->  */}
                                    <div className="row">
                                        {item.image_link_buttons != undefined ? item.image_link_buttons.map((image_link_button: Models.ImageLinkButton) => (
                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                <a href={image_link_button.url} target={image_link_button.target} title={image_link_button.text} data-test="btn-video-link">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="video-thumbnail-container">
                                                                <img className="related-videos-link-image" alt={image_link_button.image_name} src={image_link_button.image_url} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {image_link_button.text == null ? '' :
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <p className="leaflet-video-title">{image_link_button.text}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </a>
                                            </div>
                                        )) : ''}
                                    </div>
                                </div>
                                : ''}
                        </div>

                        {this.state.relatedVideos != "loading" ?
                            this.state.relatedVideos != "error" ?
                                this.state.relatedVideos.length > 0 ?
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="related-videos-link-title"><Trans>Gerelateerde videos</Trans></h3>
                                            <span className="line medium-margin-bottom" ></span>
                                        </div>
                                    </div>
                                    : '' : '' : ''}
                        <div className="row leaflet-related-video-carousel">
                            <div className="col-sm-12">
                                <Carousel
                                    additionalTransfrom={0}
                                    centerMode={false}
                                    className="container-carousel row"
                                    draggable={true}
                                    focusOnSelect={false}
                                    infinite={true}
                                    itemClass="carousel_item"
                                    minimumTouchDrag={80}
                                    renderButtonGroupOutside={true}
                                    renderDotsOutside={true}
                                    responsive={{
                                        desktop: {
                                            breakpoint: {
                                                max: 3000,
                                                min: 1024,
                                            },
                                            items: 3,
                                            partialVisibilityGutter: 0,
                                            slidesToSlide: 1,
                                        },
                                        mobile: {
                                            breakpoint: {
                                                max: 464,
                                                min: 0,
                                            },
                                            items: 1,
                                            partialVisibilityGutter: 0,
                                            slidesToSlide: 1,
                                        },
                                        tablet: {
                                            breakpoint: {
                                                max: 1024,
                                                min: 464,
                                            },
                                            items: 2,
                                            partialVisibilityGutter: 0,
                                            slidesToSlide: 1,
                                        },
                                    }}
                                    showDots={false}
                                    sliderClass="carousel_slider"
                                    swipeable={true}
                                >
                                    {this.state.relatedVideos != "loading" ?
                                        this.state.relatedVideos != "error" ?
                                            this.state.relatedVideos.map((video: Models.RelatedVideo) =>
                                                <div className="mouse-over small-padding-top small-padding-bottom" onClick={(async () => { await this.changeVideo(video.video.id); this.setState({ modalTitle: video.video.title }); })}>
                                                    <div className="col-sm-12">
                                                        <div className="video-thumbnail-container">
                                                            <img className="video-thumbnail-play-btn" src="https://www.beeldsluiter.nl/static/widget/image/Videoplayer_icon_02.png" />
                                                            <img className="image related_video_image" alt={video.video.thumbnails != undefined ? video.video.thumbnails[0].file_name : ''} src={video.video.thumbnails != undefined ? video.video.thumbnails[0].file_url : ''} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <p className="leaflet-video-title">{video.text}</p>
                                                        {video.description != null ? <p className="related-description">{video.description}</p> : ''}
                                                    </div>
                                                </div>)
                                            : ''
                                        : ''}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

}
