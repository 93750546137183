import React, { Component } from "react";
import { Link } from "react-router-dom";

import img from "../images/beeldsluiter2.jpg";

import * as Models from '../models';

import { Trans } from "react-i18next";
import i18next from "i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-multi-carousel';
import { VisualleafletModal } from "./LeafletModal";

//A const API key/link to make it easier to change it if needed.
const api = "/api/visualleaflets";

declare global {
  interface Window{
    Widget:any;
    Bijwerkingentool: any;
  }
}


export type RelatedVideoState = {
  relatedVideos: 'loading' | Models.Video[] | 'error',
  order: 'asc'| 'desc',
  search: string,
  sorters: any,
  filters: any,
  languages: any,
};


export class RelatedVideo extends Component<{}, RelatedVideoState> {
  constructor(params: any) {
    super(params);
    this.state = {
      relatedVideos: 'loading',
      order: 'asc',
      search: "",
      sorters: "",
      filters: ["", ""],
      languages: [],

    }

    this.handleChange_filter = this.handleChange_filter.bind(this);
    this.handleChange_sort = this.handleChange_sort.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }



  //Function to create filter based on value
  handleChange_filter:any = (e: any) => {
    const value:any = e.target.value;
    const name:any = e.target.name;

    this.setState({filters: [name, value]});
  };

  //Function to create sort based on value
  handleChange_sort:any = (e: any) => {
    const value:any = e.target.value;

    this.setState({sorters: [value, "ASC"]});
  };

  handleSearch(event: any) {
    this.setState({search: event.target.value});
  }

  async getAllRelatedVideos(): Promise<Models.Video[]> {
    const res = await fetch('/api/related-videos/');
    return res.json();
  }

  componentDidMount(){
    this.getAllRelatedVideos().then( (vids: Models.Video[]) => {
      this.setState({ ...this.state, relatedVideos: vids });
    });
  }

  render() {
    return (
      <Carousel
        additionalTransfrom={0}
        centerMode={false}
        className="container-carousel"
        draggable={true}
        focusOnSelect={false}
        infinite={true}
        itemClass="carousel_item"
        minimumTouchDrag={80}
        renderButtonGroupOutside={true}
        renderDotsOutside={true}
        autoPlay={true}
        autoPlaySpeed={3500}

        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 0,
            slidesToSlide: 1,
          },
          mobile: {
            breakpoint: {
              max: 560,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 0,
            slidesToSlide: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 560,
            },
            items: 3,
            partialVisibilityGutter: 0,
            slidesToSlide: 1,
          },
        }}
        showDots={false}
        sliderClass="carousel_slider"
        swipeable={true}
      >
        {this.state.relatedVideos != 'loading' ?
          this.state.relatedVideos != 'error' ?
            this.state.relatedVideos.map((video:Models.Video) =>
              <Leaflet video={video} />
            ) : "" : ""}
      </Carousel>
    );
  }

}

type LeafletState = {
  condition: any,
  leafletRef: any,
  readMore: boolean,
  dragging: boolean,
}

class Leaflet extends Component<{video: Models.Video}, LeafletState> {
  constructor(params: any) {
    super(params);
    this.state = {
      condition: true,
      leafletRef: React.createRef(),
      readMore: false,
      dragging: false,
    }
  }

  render() {
  let item = this.props.video;

    return (
      <div key={item.id} className="card" data-related-video={item.title}>
        <Link
          to={`/video/${item.title}`}
          type="button"
          className="btn btn-default btn-green leaflet-button m-0 p-0"
          onClick={(() => {
            this.state.leafletRef.current.showModal();
            window.bs_dataLayer.push({ 'event': 'related_video_modal_open', 'related_video_modal_name': item.title })
          })}
        >
          <div
            className="thumbnail_container">
            <img src={item.thumbnails[0].file_url} alt="Thumbnail cursor-pointer" className="thumbnail" draggable="true"
              onDragStart={(e) => { e.preventDefault(); this.setState({ dragging: true }); }}
              onMouseUp={(e) => { e.preventDefault(); setTimeout(() => { this.setState({ dragging: false }) }, 200) }}
              onClick={(() => {
                console.log(this.state.dragging)
                if (this.state.dragging == false)
                  this.state.leafletRef.current.showModal();
                window.bs_dataLayer.push({ 'event': 'related_video_modal_open', 'related_video_modal_name': item.title })
              })} />
          </div>
          <p className="description p-0 m-1">
            <FontAwesomeIcon icon={faPlayCircle} /> <Trans>bekijk</Trans> {item.title}
          </p>
        </Link>
        {item != null ? <VisualleafletModal ref={this.state.leafletRef}
          video={item} activeVideo={item} /> : ''}
        {/* {item != null ? <VideoModal ref={this.state.leafletRef} item={item} isQRReference={false} /> : ''} */}

      </div>
    );
  }
}

export default RelatedVideo;
