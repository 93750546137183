import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { Trans } from "react-i18next";

import LeadVideo from '../video/BeeldsluiterShowreel.mp4'

export class Lead extends Component<{}, { leadVideo: any }> {

  constructor(props) {
    super(props);

    this.state = {
      leadVideo: React.createRef(),
    }
  }

  // componentDidMount() {
  //   this.state.leadVideo.current.play()
  // }

  render() {
    return (

      <div className="lead-container">
        <video ref={ this.state.leadVideo } className="lead-video" autoPlay={ true } loop={ true } playsInline={ true } muted={ true } controls={ false } data-object-fit>
          <source src={ LeadVideo } />
        </video>
        <div className="video-overlay"></div>
        <div className="layoutContainer">
          <div className="landing_text">
            <h1 className="landing_title"><Trans>De bijsluiter in beeld</Trans></h1>
            <ul className="landing_list">
              <li className="landing_list_item">
                <Trans>landing text 1</Trans>
              </li>
              <li className="landing_list_item">
                <Trans>landing text 2</Trans>
              </li>
              <li className="landing_list_item">
                <Trans>landing text 3</Trans>
              </li>
            </ul>
            <Link to="/#beeldsluiters" className="call_to_action_btn km_btn gtm_call_to_action" data-test="btn-to-leaflets">
              <Trans>call to action 1</Trans>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
