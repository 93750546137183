import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/static/locales/{{lng}}/{{ns}}.json',
        },

        // Locale translations can be found in the /public/locales folder, formatted in json,
        // does not worp properly, change on a later date.
        whitelist: ['en', 'nl'],
        lng: localStorage.getItem('I18N_LANGUAGE') || (window.location.host.includes('visualleaflet.com') ? 'en' : 'nl'),
        fallbackLng: 'nl',
        debug: true,

        //    // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false,
        },

        resources: {
            nl: {
                translations: {
                    'De bijsluiter in beeld': 'De bijsluiter voor medicijnen in beeld',
                    'landing text 1': 'Toegankelijk en begrijpelijk',
                    'landing text 2': 'Draagt bij aan juist gebruik van medicijnen',
                    'landing text 3': 'Beschikbaar in meerdere talen',
                    'landing text 4': 'Bekijk ook gerelateerde video\'s',

                    'call to action 1': 'Naar de Beeldsluiters',
                    'call to action 2': 'Naar de gerelateerde video\'s',

                    'Wat is de Beeldsluiter?': 'Wat is de Beeldsluiter?',

                    'De Beeldsluiter is een bijsluiter voor medicijnen, maar dan in beeld.': 'De Beeldsluiter is een bijsluiter voor medicijnen, maar dan in beeld.',
                    'Alle belangrijke informatie over een medicijn in een duidelijke animatie voorzien van gesproken tekst en ondertiteling.': 'Alle informatie over een medicijn in een duidelijke animatie voorzien van gesproken tekst en ondertiteling.',
                    'Door de combinatie van beeld, geluid en tekst wordt de inhoud begrijpelijker en beter onthouden.': 'Door de combinatie van beeld, geluid en tekst wordt de inhoud begrijpelijker en beter onthouden.',
                    'Let op': 'Let op: ',
                    'om een Beeldsluiter te kunnen bekijken heeft u een RVG-code nodig of EU-nummer nodig.': 'om een Beeldsluiter te kunnen bekijken heeft u een EU-nummer nodig.',
                    'Waar vind ik het EU-nummer?': 'Waar vind ik het EU-nummer?',
                    'De RVG code begint met "EU" en deze staat op de verpakking van uw medicijn. De plek waar de code staat kan per medicijn verschillen.': 'De code begint met "EU" en deze staat op de verpakking van uw medicijn.',
                    'Gerelateerde video\'s': 'Gerelateerde video\'s',
                    'Bijsluiter downloaden': 'Bijsluiter downloaden',
                    'Meld bijwerking': 'Meld bijwerking',
                    'Medicijnwekker zetten': 'Medicijnwekker zetten',
                    Contactinformatie: 'Contactinformatie',
                    'De Beeldsluiter is onderdeel van': 'Beeldsluiter is onderdeel van:',
                    Telefoonnummer: 'Telefoonnummer: ',
                    'Neem contact op': 'Neem contact op',
                    Naam: 'Naam',
                    Email: 'E-mail',
                    'Uw vraag': 'Uw vraag',
                    Verstuur: 'Verstuur',
                    'Vind ons online': 'Vind ons online',
                    'Wat zoekt u?': 'Wat zoekt u?',
                    Taal: 'Taal',
                    'Kies taal': 'Kies taal',
                    'Sorteer op': 'Sorteer op',
                    Alfabetisch: 'Alfabetisch',
                    Datum: 'Datum',
                    Indicatie: 'Indicatie',
                    Medicijn: 'Medicijn',
                    'Werkzame stof': 'Werkzame stof',
                    'Deel deze beeldsluiter': 'Deel deze Beeldsluiter',
                    'Geen resultaten gevonden.': 'Geen resultaten gevonden.',
                    'Er is wat fout gegaan, probeer het opnieuw.': 'Er is wat fout gegaan, probeer het opnieuw.',
                    'Lees minder': 'Lees minder.',
                    'Lees meer': 'Lees meer',
                    'Beeldsluiter bekijken': 'Beeldsluiter bekijken',
                    'Momenteel zijn er geen gerelateerde video\'s beschikbaar.': 'Momenteel zijn er geen gerelateerde video\'s beschikbaar.',

                    'Oh no, it looks like you just added text to a honeypot, if you are not a bot and used auto-fill, please reload the page and fill in the form without using this feature. ': 'Het lijkt er op dat er tekst is toegevoegd aan de honingpot, als er gebruik gemaakt wordt door een automatische invuller herlaadt dan de pagina en maak geen gebruik van dit. ',
                    '* Dit veld is vereist.': '* Dit veld is vereist.',
                    'Thanks for your reaction. We will get back to you as soon as possible.': 'Bedankt voor uw bericht, wij nemen zo spoedig mogelijk contact met u op. ',
                    'We are unable to send a mail at this time, we apologize for the inconvenience.': 'Het is niet gelukt om een het bericht te versturen, probeer het op een later moment nog een keer, of stuur een email naar info@kindermedicijn.nl',
                    bekijk: 'Bekijk',
                    Website: 'Website',
                    'Gebruikt voor': 'Gebruikt voor',
                    'Geplaatst door': 'Geplaatst door',
                    Periode: 'Periode',
                    Accepteren: 'Accepteren',
                    Meerdere: 'Meerdere',

                    Jaar: 'jaar',
                    Jaren: 'jaren',
                    Uur: 'uur',
                    Uren: 'uren',
                    Minuut: 'minuut',
                    Minuten: 'minuten',
                    Week: 'week',
                    Weken: 'weken',
                    Sessie: 'Sessie',

                    'Meer informatie': 'Meer informatie',
                    'Algemene informatie': 'Algemene informatie',
                    'Uitgebreide informatie': 'Uitgebreide informatie',

                    'omschrijving van een cookie': 'Een cookie is een klein stukje tekst dat door de beeldsluiter website op uw computer, tablet of telefoon wordt opgeslagen. Deze cookies zorgen er voor dat de beeldsluiter website uw voorkeuren onthoud (zoals, maar niet gelimiteerd tot, inloggen, taalkeuzes en visuele aanpassing) voor een langere periode. Dit is zodat deze gegevens niet bij ieder bezoek ingevuld hoeven te worden. ',
                    'omschrijving gebruik cookie': 'Iedere cookie die we gebruiken hebben wij hieronder op een rijtje gezet, samen met waar deze voor gebruikt wordt. ',
                    'algemene cookie disclaimer text': 'Cookies zijn van essentiële waarden voor de functionaliteit van de website en worden gebruikt voor analytische doeleinden. <br /> Wij maken gebruik van cookies om de gebruikerservaring van onze website te verbeteren. Gaat u akkoord met het gebruik van cookies?<br />',
                    'cookie ervaring accepteren': 'Wij maken gebruik van cookies om de gebruikerservaring van onze website te verbeteren. <br /> Gaat u akkoord met het gebruik van cookies?',

                    _GA: 'Gebruikers van elkaar onderscheiden voor Google Analytics.',
                    _GAT_: 'Aanvragen voor Google Analytics beperken.',
                    _GID: 'Gebruikers van elkaar onderscheiden voor Google Analytics.',
                    WDGT_video_: 'Diverse widget-functionaliteiten.',
                    csrftoken: 'Verificatie van formulieren van gebruikers.',
                    sessionid: 'Gebruikers van elkaar onderscheiden voor de website.',
                    django_language: 'Voor het bepalen van de taal van een gebruiker.',
                },
            },
            en: {
                translations: {
                    'De bijsluiter in beeld': 'The visual leaflet for medicines',
                    'landing text 1': 'Accessible and understandable',
                    'landing text 2': 'Enhances the correct use of medicines',
                    'landing text 3': 'Available in several languages',
                    'landing text 4': 'Watch other videos too',

                    'call to action 1': 'To the visual leaflets',
                    'call to action 2': 'To related videos',

                    'Wat is de Beeldsluiter?': 'What is a visual leaflet?',

                    'De Beeldsluiter is een bijsluiter voor medicijnen, maar dan in beeld.': 'A visual leaflet is an illustrated and animated package insert for medicines.',
                    'Alle belangrijke informatie over een medicijn in een duidelijke animatie voorzien van gesproken tekst en ondertiteling.': 'All information about a medicine in an understandable animation supported by spoken text and sub-titles.',

                    'Door de combinatie van beeld, geluid en tekst wordt de inhoud begrijpelijker en beter onthouden.': 'The combination of images, sound, and texts makes it easier to understand and remember the contents. ',
                    'Let op': 'Please note: ',
                    'om een Beeldsluiter te kunnen bekijken heeft u een RVG-code nodig of EU-nummer nodig.': 'you need an EU-number to see a Visual Leaflet.',
                    'Waar vind ik het EU-nummer?': 'Where do I find the EU-number?',
                    'De RVG code begint met "EU" en deze staat op de verpakking van uw medicijn. De plek waar de code staat kan per medicijn verschillen.': 'The code is stated on the packaging of your medicine. It starts with "EU".',
                    'Gerelateerde video\'s': 'Related videos',
                    'Bijsluiter downloaden': 'Download leaflet',
                    'Meld bijwerking': 'Report a side effect ',
                    'Medicijnwekker zetten': 'Set medicine alarm',
                    Contactinformatie: 'Contact information',
                    'De Beeldsluiter is onderdeel van': 'Visual leaflets are part of:',
                    Telefoonnummer: 'Phone number: ',
                    'Neem contact op': 'Contact',
                    Naam: 'Name',
                    Email: 'Email',
                    'Uw vraag': 'Your question',
                    Verstuur: 'Send',
                    'Vind ons online': 'Find us online',
                    'Wat zoekt u?': 'What are you looking for?',
                    Taal: 'Language',
                    'Kies taal': 'Choose language',
                    'Sorteer op': 'Sort by',
                    Alfabetisch: 'Alphabetical',
                    Datum: 'Date',
                    Indicatie: 'Indication',
                    Medicijn: 'Medicine',
                    'Werkzame stof': 'Active ingredient',
                    'Deel deze beeldsluiter': 'Share this visual leaflet',
                    'Geen resultaten gevonden.': 'No results found.',
                    'Er is wat fout gegaan, probeer het opnieuw.': 'Something went wrong, please try again.',
                    'Lees minder': 'Read less',
                    'Lees meer': 'Read more',
                    'Beeldsluiter bekijken': 'Watch Visual Leaflet',
                    'Momenteel zijn er geen gerelateerde video\'s beschikbaar.': 'There are no related video’s yet.',

                    'Oh no, it looks like you just added text to a honeypot, if you are not a bot and used auto-fill, please reload the page and fill in the form without using this feature. ': 'Oh no, it looks like you just added text to a honeypot, if you are not a bot and used auto-fill, please reload the page and fill in the form without using this feature. ',
                    '* Dit veld is vereist.': '* This field is required.',
                    'Thanks for your reaction. We will get back to you as soon as possible.': 'Thanks for your reaction. We will get back to you as soon as possible.',
                    'We are unable to send a mail at this time, we apologize for the inconvenience.': 'We are unable to send a mail at this time, we apologize for the inconvenience. ',
                    bekijk: 'watch',
                    Website: 'Website',
                    'Gebruikt voor': 'Used for',
                    'Geplaatst door': 'Published by',
                    Periode: 'Period',
                    Accepteren: 'Accept',
                    Meerdere: 'Multiple',

                    Jaar: 'year',
                    Jaren: 'years',
                    Uur: 'hour',
                    Uren: 'hours',
                    Minuut: 'minute',
                    Minuten: 'minutes',
                    Week: 'week',
                    Weken: 'weeks',
                    Sessie: 'Session',

                    'Meer informatie': 'More information',
                    'Algemene informatie': 'General information',
                    'Uitgebreide informatie': 'Detailed information',

                    'omschrijving van een cookie': 'A cookie is a short text. The website places this text on your computer, tablet, or telephone. Cookies only store your preferences. Examples are log in details, language preferences, and some visual modifications. You do not have to fill in or change these things again during your next visit to the website.',
                    'omschrijving gebruik cookie': 'We have listed the cookies that we use, and indicated why we use them.',
                    'algemene cookie disclaimer text': 'Cookies are essential for the functionality of a website and are used for analytical purposes. <br/> We use cookies to enhance your experience of our website. Do you agree with the use of these cookies?<br/>',
                    'cookie ervaring accepteren': 'We use cookies to enhance your experience of our website. Do you agree with the use of these cookies?',

                    _GA: 'Identify users for Google analytics.',
                    _GAT_: 'Restrict the applications for Google Analytics.',
                    _GID: 'Identify users for Google analytics.',
                    WDGT_video_: 'Diverse widget functionalities.',
                    csrftoken: 'Verification of forms by users.',
                    sessionid: 'Identify users for this website.',
                    django_language: 'In order to determine the language of the user.',
                },
            },
        },
    });

export default i18next;
