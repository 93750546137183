import React, {Component} from 'react';
import {Trans} from 'react-i18next';

import {getCookie} from '../js/utils/common';
import Facebook from '../images/Icon_SocialMedia_Facebook.png';
import LinkedIn from '../images/Icon_SocialMedia_LinkedIn.png';
import Twitter from '../images/Icon_SocialMedia_Twitter.png';
import Instagram from '../images/Icon_SocialMedia_Instagram.png';


/* eslint-disable-next-line import/prefer-default-export */
export class Footer extends Component {
    //  {/* honypt value should always be empty,
    //  if not a bot has filled in something, thus we should ignore the message */}
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            question: '',
            errors: [],
            success: '',
            error_message: '',
            sent_email: false,
        };

        this.hpt = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.SubmitContactForm = this.SubmitContactForm.bind(this);
    }

    handleInputChange(event) {
        const {target} = event;
        const {value} = target;
        const {name} = target;
        this.setState({[name]: value});
    }

    SubmitContactForm(e) {
        e.preventDefault();

        const form = e.target;

        if (this.state.sent_email) {
            return;
        }
        if (this.hpt.current.value !== '') {
            this.setState({
                error_message:
                    'Oh no, it looks like you just added text to a honeypot, if you are not a bot and ' +
                    'used auto-fill, please reload the page and fill in the form without using this feature. ',
            });
            return;
        }
        fetch('/contact-form/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                message: this.state.question,
                csrfmiddleware: getCookie('csrftoken'),
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 'error') {
                    this.setState((prevState) => ({
                        ...prevState,
                        errors: json.error_messages,
                        error_message: '',
                        success: '',
                    }));
                }
                else if (json.status === 'error_message') {
                    this.setState((prevState) => ({
                        ...prevState,
                        errors: [],
                        error_message: json.error_message,
                        success: '',
                    }));
                }
                else {
                    this.setState((prevState) => ({
                        ...prevState,
                        errors: [],
                        success: json.success_message,
                        error_message: '',
                        sent_email: true,
                    }), () => {
                        form.reset();
                    });
                }
            });
    }

    render() {
        return (
            <footer className='footer section-padding-bottom'>
                <div className='footerContent layoutContainer'>
                    {/* Footer contact info */}
                    <div className=' footerColumnContent footerContactInfo'>
                        <h2 className='footerTitle km_title'>
                            <Trans>Contactinformatie</Trans>
                        </h2>
                        <p>
                            <Trans>De Beeldsluiter is onderdeel van</Trans>
                        </p>
                        <p>
                            Kindermedicijn B.V. <br />
                            Bovendijk 163 <br />
                            3045 PD Rotterdam
                        </p>
                        <p>
                            <Trans>Telefoonnummer</Trans>
                            <br /> +31 (0)10 236 36 60 <br />
                            Website:{' '}
                            <a
                                className='link'
                                href='https://kindermedicijn.nl/'
                            >
                                www.kindermedicijn.nl
                            </a>
                            <br />
                            <Trans>Email</Trans>:{' '}
                            <a
                                className='link'
                                href='mailto:info@kindermedicijn.nl?Subject=Uw%20vraag'
                                target='_top'
                            >
                                info@kindermedicijn.nl
                            </a>
                        </p>
                    </div>
                    {/* Footer find us online */}
                    <div className=' footerColumnContent footerSocialMediaInfo'>
                        <h2 className='footerTitle km_title'>
                            <Trans>Vind ons online</Trans>
                        </h2>
                        <div className='social_block'>
                            <a
                                href='https://www.facebook.com/kindermedicijn/'
                                className='socialMediaLink'
                                target='blank'
                                data-test='footer-facebook'
                            >
                                <img
                                    className='social image-fluid gtm_facebook'
                                    src={Facebook}
                                    alt=''
                                />
                                <span className='username'>
                                    /kindermedicijn
                                </span>
                            </a>
                            <a
                                href='https://www.linkedin.com/company/kindermedicijn/?originalSubdomain=nl'
                                className='socialMediaLink'
                                target='blank'
                                data-test='footer-linkedin'
                            >
                                <img
                                    className='social linkedIn image-fluid gtm_linkedin'
                                    src={LinkedIn}
                                    alt=''
                                />
                                <span className='username'>
                                    @kindermedicijn
                                </span>
                            </a>
                            <a
                                href='https://x.com/kindermedicijn'
                                className='socialMediaLink'
                                target='blank'
                                data-test='footer-x'
                            >
                                <img
                                    className='social image-fluid gtm_twitter'
                                    src={Twitter}
                                    alt=''
                                />
                                <span className='username'>
                                    /kindermedicijn
                                </span>
                            </a>
                            <a
                                href='https://www.instagram.com/kindermedicijn/'
                                className='socialMediaLink'
                                target='blank'
                                data-test='footer-instagram'
                            >
                                <img
                                    className='social image-fluid gtm_instagram'
                                    id='instagram-fluid'
                                    src={Instagram}
                                    alt=''
                                />
                                <span className='username'>
                                    /kindermedicijn
                                </span>
                            </a>
                        </div>
                    </div>
                    {/* Footer contact us */}
                    <div className='footerColumnContent footerContactForm'>
                        <h2 className='footerTitle km_title'>
                            <Trans>Neem contact op</Trans>
                        </h2>
                        <form
                            className='footer_contact'
                            onSubmit={this.SubmitContactForm}
                        >
                            <CSRFToken />
                            <input
                                type='text'
                                name='hpt'
                                className='hpt-input-field'
                                ref={this.hpt}
                            />
                            {this.state.success !== '' ? (
                                <div className='alert alert-success'>
                                    <p style={{'margin-bottom': 0}}>
                                        <Trans>{this.state.success}</Trans>
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                            {this.state.error_message !== '' ? (
                                <div className='alert alert-danger'>
                                    <p>
                                        <Trans>
                                            {this.state.error_message}
                                        </Trans>
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className='form-group'>
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor='name' className='compulsory'>
                                    <Trans>Naam</Trans>
                                </label>
                                <input
                                    onChange={this.handleInputChange}
                                    name='name'
                                    type='text'
                                    className='form-control'
                                />
                                {this.state.errors.name !== undefined ? (
                                    <label
                                        htmlFor='name'
                                        className='small-text-error'
                                    >
                                        <Trans>{this.state.errors.name}</Trans>
                                    </label>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='form-group'>
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor='email' className='compulsory'>
                                    <Trans>Email</Trans>
                                </label>
                                <input
                                    onChange={this.handleInputChange}
                                    name='email'
                                    type='email'
                                    className='form-control'
                                />
                                {this.state.errors.email !== undefined ? (
                                    <label
                                        htmlFor='email'
                                        className='small-text-error'
                                    >
                                        <Trans>{this.state.errors.email}</Trans>
                                    </label>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='form-group'>
                                <label
                                    htmlFor='question'
                                    className='compulsory'
                                >
                                    <Trans>Uw vraag</Trans>{' '}
                                </label>
                                <textarea
                                    onChange={this.handleInputChange}
                                    name='question'
                                    className='form-control'
                                    cols={30}
                                    rows={4}
                                />
                                {this.state.errors.question !== undefined ? (
                                    <label
                                        htmlFor='question'
                                        className='small-text-error'
                                    >
                                        <Trans>
                                            {this.state.errors.question}
                                        </Trans>
                                    </label>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='submit'>
                                <button type='submit' className='km_btn'>
                                    <Trans>Verstuur</Trans>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </footer>
        );
    }
}

function CSRFToken() {
    return (
        <input
            type='hidden'
            name='csrfmiddlewaretoken'
            value={getCookie('csrftoken')}
        />
    );
}
