import React, { Component } from "react";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
// import ModalBody from "react-bootstrap/ModalBody";
// import ModalHeader from "react-bootstrap/ModalHeader";
// import ModalFooter from "react-bootstrap/ModalFooter";
// import ModalTitle from "react-bootstrap/ModalTitle";

import RVG_code from "../images/WaarVindIkDeCode_Ontwerp2PNG.png";
// import BeeldsluiterPoster from "../images/intro_thumbnail.jpg";
import BeeldsluiterPoster from "../images/beeldsluiter-poster.jpg";
import IntroVideo from "../video/product-video-beeldsluiter.mp4";

export type IntroState = {
  showHide: boolean;
  videoTenpercent: Boolean;
  videoTwentypercent: Boolean;
  videoThirtypercent: Boolean;
  videoFortypercent: Boolean;
  videoFiftypercent: Boolean;
  videoSixtypercent: Boolean;
  videoSeventypercent: Boolean;
  videoEightypercent: Boolean;
  videoNinetypercent: Boolean;
  videoHunderdpercent: Boolean;
};
export class Introduction extends Component<{}, IntroState> {

  constructor(params: any, state: any) {
    super(params, state);
    this.state = {
      showHide: false,
      videoTenpercent: false,
      videoTwentypercent: false,
      videoThirtypercent: false,
      videoFortypercent: false,
      videoFiftypercent: false,
      videoSixtypercent: false,
      videoSeventypercent: false,
      videoEightypercent: false,
      videoNinetypercent: false,
      videoHunderdpercent: false,
    };

    this.handleModalShowHide = this.handleModalShowHide.bind(this);
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }



  introVideoTimeUpdated = (e) => {
    let currentTime = Math.floor((e.target.currentTime / e.target.duration) * 100)
    if (currentTime == 10 && !this.state.videoTenpercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoTenpercent: true,
        videoHunderdpercent: false
      })
    } else if (currentTime == 20 && !this.state.videoTwentypercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoTwentypercent: true,
        videoTenpercent: false
      })
    } else if (currentTime == 30 && !this.state.videoThirtypercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoThirtypercent: true,
        videoTwentypercent:false
      })
    } else if (currentTime == 40 && !this.state.videoFortypercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoFortypercent: true,
        videoThirtypercent: false
      })
    } else if (currentTime == 50 && !this.state.videoFiftypercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoFiftypercent: true,
        videoFortypercent: false
      })
    } else if (currentTime == 60 && !this.state.videoSixtypercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoSixtypercent: true,
        videoFiftypercent: false
      })
    } else if (currentTime == 70 && !this.state.videoSeventypercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoSeventypercent: true,
        videoSixtypercent: false

      })
    } else if (currentTime == 80 && !this.state.videoEightypercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoEightypercent: true,
        videoSeventypercent: false
      })
    } else if (currentTime == 90 && !this.state.videoNinetypercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoNinetypercent: true,
        videoEightypercent: false,
      })
    } else if (currentTime == 100 && !this.state.videoHunderdpercent) {
      this.dataLayerPush(currentTime)
      this.setState({
        videoHunderdpercent: true,
        videoNinetypercent: false
      })
    }
  }

  dataLayerPush = (currentTime) => {
    window.bs_dataLayer.push({ 'event': 'into_video', 'into_video_current_time': currentTime+' %' })
  }



  render() {
    return (
      <>
        <div className="layoutContainer section-padding" ref="">
          <div id="wat-is-de-beeldsluiter" className="introduction">
            {/* Introduction video */}

            <div className="intro_video">
              <video controls preload="none" poster={BeeldsluiterPoster} onTimeUpdate={this.introVideoTimeUpdated} >
                <source src={IntroVideo} />
              </video>
            </div>

            <div className="intro_text">
              <h1 className="intro_title km_title">
                <Trans>
                  Wat is de Beeldsluiter?
                </Trans>
              </h1>
              <p>
                <Trans>
                  De Beeldsluiter is een bijsluiter voor medicijnen, maar dan in beeld.
                </Trans>
              </p>
              <p>
                <Trans>
                  Alle belangrijke informatie over een medicijn in een duidelijke animatie voorzien van gesproken tekst en ondertiteling.
                </Trans>
              </p>
              <p >
                <span className="disclaimer"><Trans>Let op</Trans></span>
                <Trans>om een Beeldsluiter te kunnen bekijken heeft u een RVG-code nodig of EU-nummer nodig.</Trans>
              </p>
              <button className="find-rvg-code-btn gtm-rvg-code-btn" type="button" onClick={this.handleModalShowHide}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="icon"
                />{" "}
                <Trans>Waar vind ik het EU-nummer?</Trans>
              </button>
              <Modal
                show={this.state.showHide}
                onHide={this.handleModalShowHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title className="km_title"><Trans>Waar vind ik het EU-nummer?</Trans></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="RVG_code_modal_content">
                    <img src={RVG_code} alt="RVG_code" className="RVG_code_img" />
                    <p>
                      <Trans>
                        De RVG code begint met "EU" en deze staat op de verpakking
                        van uw medicijn. De plek waar de code staat kan per
                        medicijn verschillen.
                    </Trans>
                    </p>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

          </div>
        </div>
      </>
    );
  }
}

export default Introduction;
