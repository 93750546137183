import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Trans } from "react-i18next";

export type CookieState = {
  cookie_information: boolean,
  cookie_class_name: string;
};

export class Cookies extends Component<{},CookieState> {
  constructor(props) {
    super(props);
    this.state = {
      cookie_information: false,
      cookie_class_name: "",
    };
    this.cookieAccept = this.cookieAccept.bind(this);
    this.CookieInformationModalToggle = this.CookieInformationModalToggle.bind(this);
    this.cookieAcceptFromModal = this.cookieAcceptFromModal.bind(this)
  }

  componentDidMount() {
    let cookieAcceptedStatus = localStorage.getItem("cookieAccepted")

    if (cookieAcceptedStatus) {
      this.setState({
        cookie_class_name: "cookie-disclaimer cookie-disclaimer-accepted"
      })
    } else {
      this.setState({
        cookie_class_name: "cookie-disclaimer"
      })
    }
  }

  cookieAccept() {
    localStorage.setItem("cookieAccepted", 'true')

    this.setState({
      cookie_class_name: "cookie-disclaimer cookie-disclaimer-accept",
    })
  }

  cookieAcceptFromModal() {
    localStorage.setItem("cookieAccepted", 'true')

    this.setState({
      cookie_class_name: "cookie-disclaimer cookie-disclaimer-accept",
      cookie_information: !this.state.cookie_information
    })

    console.log(this.state.cookie_information)
  }

  CookieInformationModalToggle() {
    this.setState({ cookie_information: !this.state.cookie_information });
  }

  render() {
    return (
      <div className={this.state.cookie_class_name}>
        <div className="layoutContainer">
          <div className="cookie-heading">
            <h3>Cookies</h3>
          </div>
          <div className="cookie-body">
            <p><Trans>algemene cookie disclaimer text</Trans>
              <a onClick={this.CookieInformationModalToggle}>
                <Trans>Meer informatie</Trans>
              </a>
            </p>
          </div>
          <div className="acceptButton">
            <button className="km_btn" onClick={this.cookieAccept}>
              <Trans>Accepteren</Trans>
            </button>
          </div>
        </div>
        <Modal
          show={this.state.cookie_information}
          onHide={this.CookieInformationModalToggle}
          size="lg"
          aria-labelledby="Cookie information"
          centered
          className="cookiesInfoModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="km_title">Cookies</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="generalInfo cookie-section">
              <h3><Trans>Algemene informatie</Trans></h3>
              <p>
                <Trans>omschrijving van een cookie</Trans>
              </p>
            </div>
            <div className="inDepthInfo cookie-section">
              <h3><Trans>Uitgebreide informatie</Trans></h3>
              <p><Trans>omschrijving gebruik cookie</Trans></p>
              <div className="table">
                <div className="grid-row header">
                  <div className="cell"><Trans>Naam</Trans></div>
                  <div className="cell"><Trans>Gebruikt voor</Trans></div>
                  <div className="cell"><Trans>Geplaatst door</Trans></div>
                  <div className="cell"><Trans>Periode</Trans></div>
                </div>
                <div className="grid-row">
                  <div className="cell" data-title="Name">
                    _GA
                  </div>
                  <div className="cell" data-title="Usage">
                    <Trans>_GA</Trans>
                  </div>
                  <div className="cell" data-title="Placedby">
                    Google
                  </div>
                  <div className="cell" data-title="Period">
                    2 <Trans>Jaar</Trans>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="cell" data-title="Name">
                    _GAT_*
                  </div>
                  <div className="cell" data-title="Usage">
                    <Trans>_GAT_</Trans>
                  </div>
                  <div className="cell" data-title="Placedby">
                    Google
                  </div>
                  <div className="cell" data-title="Period">
                    1 <Trans>Minuut</Trans>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="cell" data-title="Name">
                    _GID
                  </div>
                  <div className="cell" data-title="Usage">
                    <Trans>_GID</Trans>
                  </div>
                  <div className="cell" data-title="Placedby">
                    Google
                  </div>
                  <div className="cell" data-title="Period">
                    24 <Trans>Uur</Trans>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="cell" data-title="Name">
                    WDGT_video_*
                  </div>
                  <div className="cell" data-title="Usage">
                    <Trans>WDGT_video_</Trans>
                  </div>
                  <div className="cell" data-title="Placedby">
                    Kindermedicijn
                  </div>
                  <div className="cell" data-title="Period">
                    <Trans>Sessie</Trans>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="cell" data-title="Name">
                    csrftoken
                  </div>
                  <div className="cell" data-title="Usage">
                    <Trans>csrftoken</Trans>
                  </div>
                  <div className="cell" data-title="Placedby">
                    Kindermedicijn
                  </div>
                  <div className="cell" data-title="Period">
                    1 <Trans>Jaar</Trans>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="cell" data-title="Name">
                    sessionid
                  </div>
                  <div className="cell" data-title="Usage">
                    <Trans>sessionid</Trans>
                  </div>
                  <div className="cell" data-title="Placedby">
                    Kindermedicijn
                  </div>
                  <div className="cell" data-title="Period">
                    2 <Trans>Weken</Trans>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="cell" data-title="Name">
                    django_language
                  </div>
                  <div className="cell" data-title="Usage">
                    <Trans>django_language</Trans>
                  </div>
                  <div className="cell" data-title="Placedby">
                    Kindermedicijn
                  </div>
                  <div className="cell" data-title="Period">
                    <Trans>Sessie</Trans>
                  </div>
                </div>
              </div>
            </div>
            <div className="question cookie-section">
              <p>
                <Trans>cookie ervaring accepteren</Trans>
              </p>
            </div>
            <button className="km_btn" onClick={this.cookieAcceptFromModal}>
              <Trans>Accepteren</Trans>
            </button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Cookies;
