import React, { Component } from "react";
import { Link } from "react-router-dom";

import img from "../images/beeldsluiter2.jpg";
import noPackage from "../images/no_package.png";

import Widget from "./Widget_link";
import * as Models from '../models';

import { VisualleafletModal } from '../components/LeafletModal';
import { Trans } from "react-i18next";
import i18next from "i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlayCircle, faLandmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Carousel from "react-multi-carousel";

//A const API key/link to make it easier to change it if needed.
const api = "/api/visualleaflets";

declare global {
  interface Window {
    Widget: any;
    bs_dataLayer: any;
  }
}


export type ApiState = {
  visualleaflets: 'loading' | Models.Visualleaflet[] | 'error',
  order: 'asc' | 'desc',
  search: string,
  sorters: any,
  filters: any,
  languages: any,
  selected_language: Models.Language,
  grouped: null | Models.Visualleaflet,
  typingTimeout: any
};


export class Api extends Component<{}, ApiState> {
  constructor(params: any) {
    super(params);

    const isVisualleaflet = window.location.host.includes('visualleaflet.com');

    this.state = {
      visualleaflets: 'loading',
      order: 'asc',
      search: "",
      sorters: ["", ""],
      filters: ["", ""],
      languages: [],
      selected_language: {
        code: isVisualleaflet ? 'en' : 'nl',
        name: isVisualleaflet ? 'Engels' : 'Nederlands'
      } as Models.Language,
      grouped: null,
      typingTimeout: null
    }

    const script = document.createElement("script");
    script.src = "/static/widget/widget.js";
    script.async = false;
    document.body.appendChild(script);

    this.handleChange_filter = this.handleChange_filter.bind(this);
    this.handleChange_sort = this.handleChange_sort.bind(this);

    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.sort = this.sort.bind(this);
    this.changeGrouped = this.changeGrouped.bind(this);
  }

  sort(current: Models.Visualleaflet, next: Models.Visualleaflet, type) {
    if (current.videos.filter((vid) => vid.language.code == this.state.selected_language.code).length == 0 ||
      next.videos.filter((vid) => vid.language.code == this.state.selected_language.code).length == 0 ||

      current.videos.filter((vid) => vid.language.code == this.state.selected_language.code)[0].indication == undefined ||
      next.videos.filter((vid) => vid.language.code == this.state.selected_language.code)[0].indication == undefined ||
      current.videos.filter((vid) => vid.language.code == this.state.selected_language.code)[0].substance == undefined ||
      next.videos.filter((vid) => vid.language.code == this.state.selected_language.code)[0].substance == undefined
    )
      return -1;

    if (type[0] == "indication")
      return current.videos.filter((vid) => vid.language.code == this.state.selected_language.code)[0].indication.name.toLowerCase() > next.videos.filter((vid) => vid.language.code == this.state.selected_language.code)[0].indication.name.toLowerCase() ? 1 : -1;
    else if (type[0] == "substance")
      return current.videos.filter((vid) => vid.language.code == this.state.selected_language.code)[0].substance.name.toLowerCase() > next.videos.filter((vid) => vid.language.code == this.state.selected_language.code)[0].substance.name.toLowerCase() ? 1 : -1;

    return current.name.toLowerCase() > next.name.toLowerCase() ? 1 : -1;
  }

  //Function to create filter based on value
  handleChange_filter: any = (e: any) => {
    const value: any = e.target.value;
    const name: any = e.target.name;

    this.setState({ filters: [name, value] });
  };

  handleChangeLanguage(e: any) {
    const value: any = e.target.value;
    const name: any = e.target.options[e.target.selectedIndex].getAttribute('name');

    window.bs_dataLayer.push({'event':'language_filter_selected','language_filter_name':name});

    this.setState({ selected_language: { code: value, name: name }, })
  }

  //Function to create sort based on value
  handleChange_sort: any = (e: any) => {
    const value: any = e.target.value;
    window.bs_dataLayer.push({'event':'sort_selected','sort_by':value})
    this.setState({ sorters: [value, "ASC"] });
  };

  fireSearchDatalayerEvent = (val) => {
    if (val){
      let searchterm = val.replace(/\s+/g,' ').trim().toUpperCase()
      window.bs_dataLayer.push({'event':'search_term_event', 'search_term':searchterm})
    }
  }

  handleSearch(event: any) {
    this.setState({ search: event.target.value });
    if( this.state.typingTimeout){
      clearTimeout(this.state.typingTimeout)
    }
    this.setState({
      typingTimeout:setTimeout(this.fireSearchDatalayerEvent, 2000,  event.target.value )
    })
  }

  async getAllLeaflets(): Promise<Models.Visualleaflet[]> {
    const res = await fetch('/api/visualleaflets/');
    return res.json();
  }

  async getAllLanguages(): Promise<Models.Language[]> {
    const res = await fetch('/api/languages/');
    return res.json();
  }

  componentDidMount() {
    this.getAllLeaflets().then((leafs: Models.Visualleaflet[]) => {
      this.setState({ ...this.state, visualleaflets: leafs });
    });

    this.getAllLanguages().then((langs: Models.Language[]) => {
      this.setState({ ...this.state, languages: langs });
    });

    // Filter the visual leaflet's language to that of the website, and default to "nl".
    let langCode = localStorage.getItem('I18N_LANGUAGE');
    if (!langCode) {
      langCode = "nl";
    }

    this.setState({ selected_language: { code: langCode, name: langCode == "nl" ? "Nederlands" : "Engels" }, })

    window.addEventListener('popstate', this.handlePopstate);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopstate);
  }

  handlePopstate = () => {
    if(this.state.grouped != null){
      this.setState({
        grouped: null
      });
    }
  }

  changeGrouped(leaflet: Models.Visualleaflet | null) {
    this.setState({grouped: leaflet});
  }

  render() {
    let leaflets =
      this.state.visualleaflets != 'loading' ?
        this.state.visualleaflets != 'error' ?
          this.state.visualleaflets.filter(
            (leaflet: Models.Visualleaflet) => this.state.search != "" ? (
              leaflet.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
              leaflet.videos.filter(vid => vid.substance != null ? vid.substance.name.toLowerCase().includes(this.state.search.toLowerCase()) : false).length > 0 ||
              leaflet.videos.filter(vid => vid.indication != null ? vid.indication.name.toLowerCase().includes(this.state.search.toLowerCase()) : false).length > 0 ) : true)
            .length > 0 ?
            this.state.visualleaflets.filter(
              (leaflet: Models.Visualleaflet) => leaflet.videos.length > 0)
              .filter((leaflet: Models.Visualleaflet) => this.state.selected_language.code != '' ? (leaflet.videos.filter((video: Models.Video) => video.language.code == this.state.selected_language.code).length > 0) : true)
              .filter((leaflet: Models.Visualleaflet) => this.state.search != "" ? (
                leaflet.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                leaflet.videos.filter(vid => vid.substance != null ? vid.substance.name.toLowerCase().includes(this.state.search.toLowerCase()) : false).length > 0 ||
                leaflet.videos.filter(vid => vid.indication != null ? vid.indication.name.toLowerCase().includes(this.state.search.toLowerCase()) : false).length > 0 ) : true)
                // .filter((leaflet: Models.Visualleaflet) => this.state.grouped != null ? this.state.grouped == leaflet : true)
              .sort((a, b) => this.sort(a, b, this.state.sorters)).map((leaflet: Models.Visualleaflet) =>
                <Leaflet visualleaflet={leaflet} language={this.state.selected_language} grouped={this.state.grouped} callback={this.changeGrouped} />
              )
            : []
          : []
        : [];


    return (
      <>
        <form className="visualleafletNavigationTools" >
          <div className="dropDown">
            <label className="dropDownTitle" htmlFor="language">
              <Trans>Taal</Trans>: &nbsp;
                  </label>
            <select
              className="custom-select slectBox"
              id="languageSelector"
              onChange={this.handleChangeLanguage}
            >
              {/* <option selected value="">
                      {i18next.t('Kies taal').toString()}
                    </option> */}

              {
              //@ts-ignore
              this.state.languages.length > 0 ? this.state.languages.map((lang: Models.Language) => <option selected={lang.code === localStorage.getItem('I18N_LANGUAGE')} value={lang.code} name={lang.name}> {lang.name}</option>) : ''}
            </select>
          </div>
          <div className="dropDown">
            <label className="dropDownTitle" htmlFor="sort">
              {i18next.t('Sorteer op').toString()}: &nbsp;
                  </label>
            <select
              className="custom-select slectBox"
              id="sort"
              onChange={this.handleChange_sort}
            >
              <option className="gtm_sort_medicine" value="name">{i18next.t('Medicijn').toString()}</option>
              <option className="gtm_sort_indication" value="indication">{i18next.t('Indicatie').toString()}</option>
              <option className="gtm_sort_substance" value="substance">{i18next.t('Werkzame stof').toString()}</option>
            </select>
          </div>
          <div className="visualleafletSearch">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={i18next.t('Wat zoekt u?')}
                aria-label="Search"
                aria-describedby="button-addon2"
                onChange={this.handleSearch}
                onKeyDown={(e) => { if (e.keyCode == 13) e.preventDefault() }}
                name="name"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-default btn_search "
                  type="button"
                  id="button-addon2"
                  onClick={((e) => { e.preventDefault(); })}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </div>
          </div>
        </form>
        {this.state.grouped != null ?
          <div className="beeldsluiters">
            <div className="visualleafletCart cursor-pointer" onClick={() => { this.setState({grouped: null})} }>
              <h3 className="back-button cursor-pointer leaflet-title"><FontAwesomeIcon icon={faArrowLeft} /> Terug</h3>
            </div>
          </div>
        : ''}
        <div className="beeldsluiters">
          {leaflets.length > 0 ? leaflets : <h3><Trans>Geen resultaten gevonden.</Trans></h3>}
        </div>
      </>
    );
  }

}

interface LeafletProps {
  visualleaflet: Models.Visualleaflet,
  language: Models.Language
  callback?: Function,
  grouped: Models.Visualleaflet,
}

interface LeafletState {
  condition: any,
  leafletRefs: any[],
  readMore: boolean,
  // active_video: Models.Video
  visualleaflet: Models.Visualleaflet,
  videos: Models.Video[],
  language: Models.Language,
  grouped: Models.Visualleaflet,

}

class Leaflet extends Component<LeafletProps, LeafletState> {
  constructor(params: any) {
    super(params);
    let refs = [];
    this.props.visualleaflet.videos.filter((vid: Models.Video) => vid.language.code == this.props.language.code).forEach((item, index, array) => refs.push(React.createRef()));

    this.state = {
      condition: true,
      leafletRefs: refs,
      readMore: false,
      // active_video: this.props.visualleaflet.videos.filter((vid) => { return vid.language.code == this.props.language.code })[0],
      visualleaflet: this.props.visualleaflet,
      videos: this.props.visualleaflet.videos.filter((vid: Models.Video) => vid.language.code == this.props.language.code),
      language: this.props.language,
      grouped: this.props.grouped,
    }

    this.ShowModalOrCallback = this.ShowModalOrCallback.bind(this);
  }

  componentWillReceiveProps(newProps: LeafletProps) {
    let acv = newProps.visualleaflet.videos.filter((vid) => { return vid.language.code == newProps.language.code });
    this.setState({
      visualleaflet: newProps.visualleaflet,
      videos: newProps.visualleaflet.videos.filter((vid: Models.Video) => vid.language.code == newProps.language.code),
      language: newProps.language,
      grouped: newProps.grouped,
    })
  }

  ShowModalOrCallback(grouped, index) {
    if(this.props.callback && this.state.videos.length > 1 && !grouped){
      this.props.callback(this.state.visualleaflet);

    } else {
      window.bs_dataLayer.push({'event':'visualleaflet_modal_open','clicked_visualleaflet_name':this.state.leafletRefs[index].current.props.activeVideo.title});
      this.state.leafletRefs[index].current.showModal(this.state.visualleaflet);
    }
  }

  render() {
    let videos = this.state.grouped != null ? ( this.state.grouped.id == this.state.visualleaflet.id ? this.state.videos : [] ) : [this.state.videos[0]];
    let leaflet = this.state.visualleaflet;

    return (
      <>
      {videos.map((video, index, array) => {
        return (
        <div id={video.id.toString()} key={leaflet.id} className="visualleafletCart" data-visual-leaflet={leaflet.name}>
          <h2 className="leaflet-title cursor-pointer">
              <Link className="leaflet-title cursor-pointer" to={`/leaflet/${leaflet.name}`}
                onClick={(() => {
                  this.ShowModalOrCallback(this.props.grouped, index);
                })}>{this.props.grouped ? video.title : leaflet.name}
              </Link>
            </h2>
            <p className="visualleaflet-indication-block cursor-pointer">
              <Link className="visualleaflet-indication-block cursor-pointer text-dark" to={`/leaflet/${leaflet.name}`}
                onClick={(() => {
                  this.ShowModalOrCallback(this.props.grouped, index);
                })}>
                {video.indication != null ? <span><Trans>Indicatie</Trans>: {!this.props.grouped ? (this.state.videos.length > 1 ? <Trans>Meerdere</Trans> : video.indication.name) : video.indication.name}<br /></span> : ''}
                {video.substance != null ? <span><Trans>Werkzame stof</Trans>: {!this.props.grouped ? (this.state.videos.length > 1 ? <Trans>Meerdere</Trans> : video.substance.name) : video.substance.name}</span> : ''}
              </Link>
            </p>
          <div className="thumbnail-container">
            <Carousel
              additionalTransfrom={0}
              renderButtonGroupOutside={false}
              centerMode={false}
              className="container-carousel"
              draggable={false}
              focusOnSelect={false}
              infinite={true}
              itemClass="visual-carousel-item"
              minimumTouchDrag={40}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                  partialVisibilityGutter: 0,
                  slidesToSlide: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                  partialVisibilityGutter: 0,
                  slidesToSlide: 1,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 0,
                  slidesToSlide: 1,
                },
              }}
              showDots={false}
              sliderClass="carousel-slider"
              swipeable={false}
              autoPlay={false}
              autoPlaySpeed={5000}
              customLeftArrow={<div></div>}
              customRightArrow={<div></div>}
            >
              {video.thumbnails.map((e) =>
                <img
                  src={e.file_url}
                  alt="Thumbnail"
                  className="thumbnail"
                />
              )}
            </Carousel>
              <div className="floating-thumbnail">
                <Link to={`/leaflet/${leaflet.name}`}
                  onClick={(() => {
                    this.ShowModalOrCallback(this.props.grouped, index);
                  })}>
                  <img
                    alt="Thumbnail"
                    className="thumbnail"
                    src={leaflet.package.url != '' ? leaflet.package.url : noPackage}
                  />
                </Link>
              </div>
            </div>
          <p className="leaflet-text">
            {!this.props.grouped ? (leaflet.video_description != null ? leaflet.video_description : video.description) : video.description != '' ? video.description : leaflet.video_description}
          </p>
            <Link className="btn btn-default btn-green leaflet-button" to={`/leaflet/${leaflet.name}`}
              onClick={(() => {
                this.ShowModalOrCallback(this.props.grouped, index);
              })}>
              <FontAwesomeIcon icon={faPlayCircle} /> <Trans>Beeldsluiter bekijken</Trans>
            </Link>
          {leaflet != null ? <VisualleafletModal ref={this.state.leafletRefs[index]}
            leaflet={leaflet} language={this.state.language} activeVideo={video} /> : ''}
        </div>
        )
      })}
      </>
    );
  }
}

export default Api;
