import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoNL from "../images/Beeldsluiter_Logo_2020_EPS.svg";
import logoEN from "../images/Beeldsluiter_Logo_2020_EN.png";
import { useTranslation, Trans } from "react-i18next";
import i18n from '../i18n';
import { Language } from '../models';

declare global {
  interface Window {
    Widget: any;
  }
}

export type HeaderState = {
  languages: "loading" | string[],
}

export class Header extends Component<{}, HeaderState> {

  constructor(props: any) {
    super(props);
    this.state = {
      languages: "loading",
    };
  }

  changeLanguage(lang: string): any {
    if (lang != i18n.options.lng)
      i18n.changeLanguage(lang).then(() => {
        i18n.options.lng = lang;
        localStorage.setItem('I18N_LANGUAGE', lang);
        this.setState({ ...this.state });
        window.location.href = window.location.href.split('#')[0];
      });
  }

  componentDidMount() {
    if (Object.keys(i18n.services.resourceStore.data) != undefined)
      this.setState({ languages: Object.keys(i18n.services.resourceStore.data) });
  }

  render() {
    return (
      <nav>
        <div className="layoutContainer navbar">
          <Link reloadDocument to="" className="logo">
            <img src={window.location.host.includes('visualleaflet.com') ? logoEN : logoNL} alt="logo" />
          </Link>
          <div className="language_selector ">
            {this.state.languages != "loading" ? this.state.languages.map((lang: string, i: number) =>
              <a onClick={(() => { this.changeLanguage(lang); })}
                className={lang.toLowerCase() == i18n.language.toLowerCase()
                  ? "language km_btn active white-link"
                  : "language km_btn white-link"}
                data-test={
                    `btn-language-${lang.toLowerCase()}`
                }
                  >
                {lang.toUpperCase()}
              </a>
            ) : ""}
          </div>
        </div>
      </nav>
    )
  }
}
