import React, {Component} from 'react';
import 'react-multi-carousel/lib/styles.css';
import {Trans} from 'react-i18next';
import {faChevronCircleUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Api} from './js/Api';
import {VisualleafletModal} from './components/LeafletModal';
import {Header} from './components/Header';
import {Footer} from './components/Footer';
import {Lead} from './components/Lead';
import {Introduction} from './components/Introduction';
import {RelatedVideo} from './components/RelatedVideo';

import {Cookies} from './components/Cookies';

class Main extends Component {
    constructor() {
        super();
        this.state = {
            condition: false,
            qr_ref: null,
            ref_id: 0,
            ref_video: null,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        // check if url contains ?ref=&video_id=
        if (window.location.href.split('?').length > 1) {
            const queryString = window.location.href.split('?')[1];
            const queryParams = queryString ? queryString.split('&') : [];

            queryParams.forEach((param) => {
                const [k, v] = param.split('=');

                if (k === 'ref' && v === 'qr') {
                    this.setState({qr_ref: true});
                }
                else if (k === 'vid_id') {
                    const refId = Number.parseInt(v, 10);
                    this.setState({ref_id: refId});

                    this.getVideo(refId).then((item) => {
                        if (item.id !== null) {
                            this.setState({ref_video: item});
                        }
                    });
                }
            });
        }
        else {
            // if not, check if there is a visualleaflet with the slug as its name defined as protocol://host/<slug>/
            let leaflet = '';
            if (window.location.href[window.location.href.length - 1] === '/') {
                leaflet = window.location.href.split('/')[
                    window.location.href.split('/').length - 2
                ];
            }
            else {
                leaflet = window.location.href.split('/')[
                    window.location.href.split('/').length - 1
                ];
            }

            if (leaflet !== '') {
                this.getLeaflet(leaflet)
                    .then((visualleaflet) => {
                        if (visualleaflet.detail === undefined) {
                            this.setState({
                                qr_ref: false,
                                ref_id: visualleaflet.video[0].id,
                                ref_video: visualleaflet.video[0],
                            });
                        }
                    })
                    .catch(() => {
                        // if there is no visualleaflet with the given name,
                        // check if there is any video with the given slug

                        this.getvideoSlugs().then((slugs) => {
                            const uc = slugs.filter(
                                (slug) => slug.slug.toUpperCase()
                                    === leaflet.toUpperCase(),
                            );
                            if (uc.length > 0) {
                                this.getVideo(uc[0].id).then((video) => {
                                    this.setState({
                                        qr_ref: false,
                                        ref_id: video.id,
                                        ref_video: video,
                                    });
                                });
                            }
                        });
                    });
            }
        }
    }

    handleClick() {
        this.setState((prevState) => ({
            condition: !prevState.condition,
        }));
    }

    async getVideo(id) {
        const res = await fetch(`/api/videos/${id}/`);
        if (!res.ok) {
            throw new Error(res.status);
        }
        const video = await res.json();

        this.setState((prevState) => ({
            ...prevState,
            ref_video: video,
        }));
    }

    async getLeaflet(id) {
        const res = await fetch(`/api/visualleaflets/${id}/`);
        if (!res.ok) {
            throw new Error(res.status);
        }
        const leaflet = await res.json();

        this.setState((prevState) => ({
            ...prevState,
            ref_leaflet: leaflet,
        }));

        return leaflet;
    }

    // eslint-disable-next-line class-methods-use-this
    async getvideoSlugs() {
        const res = await fetch('/api/videoslugs/');
        if (!res.ok) {
            throw new Error(res.status);
        }
        return res.json();
    }

    render() {
        return (
            <>
                {this.state.ref_id !== 0 && this.state.ref_video != null ? (
                    <VisualleafletModal
                        ref={this.state.leafletRef}
                        video={this.state.ref_video}
                        activeVideo={this.state.ref_video}
                        isQRReference={this.state.qr_ref}
                    />
                ) : (
                    ''
                )}
                {/* Navigation bar */}
                <Header />
                {/* Scroll to button */}
                <div
                    className='scroll_to'
                    id='gtm_back_to_top'
                    ref={this.topPageRef}
                >
                    <a
                        type='button'
                        id='to_top'
                        className='scroll_to_btn'
                        onClick={() => {
                            window.bs_dataLayer.push({event: 'back_to_top'});
                        }}
                        href='#top'
                    >
                        <FontAwesomeIcon
                            icon={faChevronCircleUp}
                            className='large-margin-right'
                        />
                    </a>
                </div>
                {/* Landing Page Section */}
                <Lead />
                {/* Introduction Page Section */}
                <Introduction />

                {/* Line */}
                <div className='layoutContainer'>
                    <span className='line' />
                </div>

                {/* Visualleaflet video section */}
                <div className='layoutContainer '>
                    <div
                        id='beeldsluiters'
                        className='section-padding beeldsluiters-container'
                    >
                        <Api />
                    </div>
                </div>

                {/* Related vids */}
                <div className='layoutContainer'>
                    <div
                        id='gerelateerde-videos'
                        className='related-videos section-padding-bottom'
                    >
                        <h1 className='km_title'>
                            <Trans>Gerelateerde video&apos;s</Trans>
                        </h1>
                        <span className='line' />
                        {/* NOTE: If the carousel is in a flexbox
                put the carousel in a div with a width of 100%.
                It won't work in a flexbox
                The width on 100% is a workaround for this so that you can
                use it in a flexbox */}
                        <div className='wrap-carousel related-videos-container'>
                            <RelatedVideo />
                        </div>
                    </div>
                </div>

                {/* Footer Section */}
                <Footer />
                {/* Cookies */}
                <Cookies />
            </>
        );
    }
}

export default Main;
