import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Trans} from 'react-i18next';

// UUID generator from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
/* eslint-disable no-bitwise */
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0; const
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
/* eslint-enable no-bitwise */

function IntakeMoment(props) {
    let events = [];

    for (let i = 0; i < props.Events.length; i++) {
        const startTime = new Date();
        // Rounds the start time to 15 minutes
        startTime.setMinutes(startTime.getMinutes() - (startTime.getMinutes() % 15));
        startTime.setDate(startTime.getDate() + props.Events[i].days_offset); // Days offset

        // The date after which no more events should be added to the calendar.
        const repeatUntil = new Date();
        repeatUntil.setMonth(repeatUntil.getMonth() + props.Events[i].repeat_months);

        const endTime = new Date(startTime); // Endtime of the event.
        endTime.setMinutes(endTime.getMinutes() + 30);

        // the weird intentation is to prevent tabs in the format. Else it's invalid iCal syntax
        const event = `BEGIN:VEVENT
DTSTART:${startTime.getFullYear()}${startTime.getMonth() + 1}${startTime.getDate()}T${startTime.getHours()}${startTime.getMinutes()}00
DTEND:${endTime.getFullYear()}${endTime.getMonth() + 1}${endTime.getDate()}T${endTime.getHours()}${endTime.getMinutes()}00
DTSTAMP:${endTime.getFullYear()}${endTime.getMonth() + 1}${endTime.getDate()}T${endTime.getHours()}${endTime.getMinutes()}00
UID:${uuidv4()}
DESCRIPTION:${props.Events[i].description}
SUMMARY:${props.EventTitle} innemen
RRULE:FREQ=${props.Events[i].frequency};UNTIL=${repeatUntil.getFullYear()}${repeatUntil.getMonth() + 1}${repeatUntil.getDate()}T${repeatUntil.getHours()}${repeatUntil.getMinutes()}00
END:VEVENT`;

        events.push(event);
    }

    events = events.join('\n'); // converts the array with events to a string to insert into calendar

    const contents = `BEGIN:VCALENDAR
PRODID:Beeldsluiter
VERSION:2.0
${events}
END:VCALENDAR
`;

    const blob = new Blob([contents], {type: 'text/calendar'});
    const url = URL.createObjectURL(blob);

    return (
        <a className="link" href={url}>
            <FontAwesomeIcon icon={['fas', 'bell']} />
            <span><Trans> Medicijnwekker zetten</Trans></span>
        </a>
    );
}

export default IntakeMoment;
